<template lang="pug">
  .login
    .contents
      h1
        LogoItem
      #firebaseui-auth-container
      .error(v-if="isError" ref="error")
        p
          strong ログインできませんでした
          | システムエラーまたは、{{ dispName }}さんのアカウントが存在しない可能性があります。
          br
          | 必要な場合は管理者へお問い合わせください。
        ActiveBtn.reload(@event="reload" btnLabel="再読み込み")
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { firebase, provider } from '@/plugins/firebase'
import LogoItem from '@/components/LogoItem.vue'
import ActiveBtn from '@/components/atoms/ActiveBtn.vue'

const firebaseui = require('firebaseui-ja')
require('firebaseui-ja/dist/firebaseui.css')

export default {
  name: 'LoginView',
  metaInfo: {
    title: 'ログイン画面',
  },
  data() {
    return {
      dispName: '',
      isError: false,
    }
  },
  components: {
    LogoItem,
    ActiveBtn,
  },
  computed: {
    ...mapState('authModule', ['isAuth']),
  },
  mounted() {
    let vm = this
    var ui = new firebaseui.auth.AuthUI(firebase.auth())
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          const userProfile = authResult.additionalUserInfo.profile
          // 初めてのログインの場合PWAのホームに追加などを促すポップアップを表示してもいいかも
          // const isNewUser = userInfo.isNewUser
          vm.login(userProfile.email).then(() => {
            if (vm.isAuth) {
              vm.$router.push({ name: 'home' })
            } else {
              vm.dispName = userProfile.name
              vm.isError = true
              return false
            }
          })
        },
      },
      signInFlow: 'redirect',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: provider.providerId,
          customParameters: provider.Ib,
        },
      ],
    })
  },
  methods: {
    ...mapActions('authModule', ['login']),
    reload() {
      this.$router.go({ name: 'login' })
    },
  },
}
</script>

<style scoped lang="scss">
.login {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background: rgba(51, 51, 51, 0.3);
  animation: fadeOut-data-v-647d64ee 1s ease 0s 1 normal;
  -webkit-animation: fadeOut-data-v-647d64ee 1s ease 0s 1 normal;
  .contents {
    position: fixed;
    box-sizing: border-box;
    border-radius: 20px;
    width: 90%;
    max-width: 450px;
    max-height: 95%;
    background: #fff;
    color: #333;
    padding: 0 0 15px;
    top: 45%;
    left: 50%;
    transition: bottom 1s;
    z-index: 15;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 25px;
      font-size: 25px;
      background-color: $ems-base-color;
      background-image: $ems-base-gradation;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 10px 0;
      img {
        width: 40px;
        margin: 0 10px 0 0;
      }
      .logo {
        color: #fff;
      }
    }
    .error {
      max-width: 400px;
      margin: 0 auto;
      padding: 0 15px 5px;
      text-align: left;
      color: #888;
      strong {
        font-size: 16px;
        display: block;
        margin: 0 0 10px;
        font-weight: bold;
        text-align: center;
        color: $ems-alert-color;
      }
      .reload {
        border-radius: 20px;
        width: 150px;
        margin: 20px auto 0;
        padding: 8px 30px;
      }
    }
  }
}
</style>
